.rev_slider_wrapper {
  height: 385px;

  .tp-caption {

    background: {
      color: $bg-opacity
    }
    padding: 1rem;
    p {
      white-space: normal;
    }
    .fa {
      color: $brand-primary;
    }

  }

  .tp-bullet {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background: {
      color: $bg-opacity
    }
  }

  .tp-bullet:hover, .tp-bullet.selected {
    background: {
      color: $brand-primary;
    }
  }

  .tp-leftarrow:before {
    content: "\f053";
    font: normal normal normal 1rem / 1 FontAwesome;
    margin-top: 14px;
  }
  .tp-rightarrow:before {
    content: "\f054";
    font: normal normal normal 1rem / 1 FontAwesome;
    margin-top: 14px;
  }

}
