.inner-header {
  position: relative;
  height: 300px;

  h1 {
    position: absolute;
    bottom: 0;
    left: 0;

    margin: 0 0 2rem 2rem
  }
}