h1 {
  display: inline-block;
  padding: .4rem;
  margin: 1rem 0;

  background: {
    color: $bg-opacity;
  }

  &.no-opacity {
    background-color: #f1f1f1;
  }
}

body {
  background-color: #f1f1f1;
}

.fa {
  color: $brand-primary;
}
.fa-inverse {
  color: white;
}

.btn {
  text-decoration: none;
}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?51512012');
  src: url('../fonts/fontello.eot?51512012#iefix') format('embedded-opentype'),
  url('../fonts/fontello.woff2?51512012') format('woff2'),
  url('../fonts/fontello.woff?51512012') format('woff'),
  url('../fonts/fontello.ttf?51512012') format('truetype'),
  url('../fonts/fontello.svg?51512012#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

main [class^="icon-"]:before, main [class*=" icon-"]:before {
  font-family: "fontello";
}

.icon-spb-metro:before { content: '\e800'; } /* '' */
.icon-no-smoking:before { content: '\e801'; } /* '' */
.icon-booking:before { content: '\e809'; } /* '' */