.gallery {
  display: flex;

  figure {
    border: 1px solid $brand-primary;
    margin-right: .5rem;
    padding: .5rem;

    flex: {
      basis: 200px;
    }

    a img {
      margin: 0;
    }
  }
}