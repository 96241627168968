@import "helpers/tether";
@import "helpers/tether-theme-arrows";

$themePrefix: "tether";
$themeName: "arrows-dark";
$arrowSize: 16px;
$backgroundColor: #000;
$color: #fff;
$useDropShadow: false;

@include tether($themePrefix: $themePrefix);
@include tether-theme-arrows($themePrefix: $themePrefix, $themeName: $themeName, $arrowSize: $arrowSize, $backgroundColor: $backgroundColor, $color: $color, $useDropShadow: $useDropShadow);
