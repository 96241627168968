.table {
  td {
    border: 0;
    padding: 0.3rem 0.75rem;
    min-width: 62px;
  }
  td.ng-scope {
    min-width: 60px;
  }
  thead th {
    font-weight: inherit;
    padding: 0.4rem 0.75rem;
    border: {
      top: 0;
      bottom: 1px solid $brand-primary;
    }
  }

  td.active, th.active {
    background: #e7d7bd;
  }
}