main {
  overflow: hidden;

  flex: none;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(xl) {
    @include make-col-span(9);
    flex: {
      grow: 0;
      shrink: 0;
    }
  }

  @media (min-width: 1400px) {
    @include make-col-span(10);
  }
}