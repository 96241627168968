aside {
  background: $bg-sidebar;

  transition: {
    property: margin;
    duration: 0.5s;
    timing-function: ease-out;
  }

  min-height: 100vh;

  flex: none;
  width: 100vw;
  margin-left: -100vw;

  @include media-breakpoint-up(md) {
    @include make-col-span(3);
    margin-left: 0;
    flex: {
      grow: 0;
      shrink: 0;
    }
  }

  @media (min-width: 1400px) {
    @include make-col-span(2);
  }

  button.close {
    @include pull-right();
    margin: 1rem;
  }

  .sidebar {
    position: relative;
    width: 100%;
    margin-top: 1rem;

    p.choose-lang {
      cursor: pointer;
    }
  }
}

aside.open {
  margin-left: -15vw;
  @include media-breakpoint-up(lg) {
    margin-left: -15vw;
  }
}