.highlights {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .item {

    flex: 0 1 auto;

    @include media-breakpoint-only(md) {
      @include make-col(3);
    }

    @include media-breakpoint-down(sm) {
      @include make-col(6);
    }

  }
}