@mixin hover-inverse() {
  background: {
    color: white;
  };
  &, & * {
    color: $navbar-light-active-color;
  }
}

aside {
  .sidebar {
    p {
      padding: 0 1rem;
      width: 100%;
      margin-bottom: .5rem;
    }

    * {
      color: $navbar-dark-active-color;
    }

    .nav-item a {
      padding-left: 3rem;
      &:hover, &.active {
        @include hover-inverse();
      }
    }

    .nav-item.active {
      @include hover-inverse();
    }

    li ul .nav-item a {
      padding-left: 4rem;
    }

    .nav-item a[data-toggle="collapse"] {
      padding-left: 1.5rem;
    }

    .main-button {
      background: {
        color: $brand-primary;
      };

      padding: {
        top: .2rem;
        bottom: .2rem;
      }

      &:hover {
        @include hover-inverse();
      }
    }

    .fa {
      padding-right: 1rem;
    }
  }
}