.parallax-container {
  padding-bottom: 3rem;

  section {
    display: flex;
    justify-content: center;
  }

  .card-deck {
    justify-content: space-around;

    .card {
      flex: {
        basis: 15rem;
        grow: 0;
        shrink: 0;
      }

      @include media-breakpoint-up(lg) {
        flex-basis: 20rem;
      }

      background: {
        color: $bg-opacity;
      }

      .card-footer {
        background: transparent;
      }

      .card-block {
        padding: 1rem;
        @include media-breakpoint-up(lg) {
          padding: $card-spacer-x;
        }
      }

      img {
        @include center-block();
        width: 13rem;
        margin-bottom: 1rem;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.7);

        @include media-breakpoint-up(lg) {
          width: 19rem;
        }
      }
    }
  }

  .main-events {
    .card {
      flex: {
        basis: 10rem;
        grow: 0;
        shrink: 0;
      }
      min-width: 0;
      @include media-breakpoint-up(lg) {
        flex-basis: 15rem;
      }
      img {
        @include center-block();
        width: 8rem;
        margin-bottom: 1rem;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.7);

        @include media-breakpoint-up(lg) {
          width: 13rem;
        }
      }
      .card-block {
        padding: 1rem;
        @include media-breakpoint-up(lg) {
          padding: $card-spacer-x;
        }
      }
    }
  }
}