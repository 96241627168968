@each $breakpoint in map-keys($grid-breakpoints) {
  @for $i from 1 through $grid-columns {
    .col-#{$breakpoint}-#{$i} {
      min-width: 0;
    }
  }
}

.inner {
  h4 {
    margin: 1rem 0 0 35px;
  }
  hr {
    margin: 1rem 35px;
    border-color: $brand-primary;
  }
  section {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
  }
}