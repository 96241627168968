.inner {
  .nav-inline {
    margin: 1rem 35px;

    .nav-link {
      padding: 3px;
      &.active {
        background-color: $bg-active;
      }
    }
  }
}