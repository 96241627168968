.reserve {
  .occupied {
    background-color: #e7d7bd;
  }

  .free {
    background-color: #F8F6F1;
  }

  .checked, .free:hover {
    background-color: #551C2D;
  }

  .breadcrumb li.active {
    font-weight: bold;
    color: black;
  }

  .table-responsive {
    margin: 10px 0 10px 0;
  }

  table .dates td:last-child {
    display: none;
  }

  .table-bordered>tbody>tr>td.free {
    border: 1px solid #DAD8D6;
  }

  .table-bordered>tbody>tr>td.occupied {
    border: 1px solid black;
  }
}

