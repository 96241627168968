@import "variables";

@import "../../bower_components/font-awesome/scss/font-awesome";
@import "../../bower_components/bootstrap/scss/bootstrap-flex";
@import "../../bower_components/tether/src/css/tether";
@import "../../bower_components/tether/src/css/tether-theme-arrows-dark";


@import "../revolution/css/settings";
@import "../revolution/css/layers.css";
@import "../revolution/css/navigation.css";

@import "typography";
@import "table";

@import "layout/body";
@import "layout/main";
@import "layout/sidebar";
@import "layout/footer";

@import "main/slider";
@import "main/highlights";
@import "main/parallax";

@import 'inner/header';
@import 'inner/nav';
@import 'inner/layout';
@import 'inner/gallery';
@import 'inner/gmap';
@import 'inner/reserve';

@import "sidebar/logo";
@import "sidebar/menu";
