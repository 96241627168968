footer {
  color: white;

  a, a:hover {
    color: white;
  }

  .widgets {
    background-color: $bg-sidebar;
    padding: 20px 0 0 20px;


    i {
      padding-right: 5px;
    }

    .row {
      justify-content: space-between;
    }

    .widget {
      flex: {
        basis: auto;
      };
    }
  }

  .bottom {
    background-color: #601221;
    padding: 20px 0 0 20px;
  }
}