$fa-font-path: '../bower_components/font-awesome/fonts';
$fa-font-size-base: 1rem;

$navbar-light-color:                rgba(93,21,42,.3);
$navbar-light-hover-color:          rgba(93,21,42,.6);
$navbar-light-active-color:         rgba(93,21,42,.8);
$navbar-light-disabled-color:       rgba(93,21,42,.15);

$link-decoration: underline;

$nav-link-padding: 0.2rem 1rem;

$brand-primary: rgb(93,21,42);
$bg-active: rgb(235,228,199);
$bg-sidebar: #782432;
$bg-opacity: rgba(255, 255, 255, 0.7);

$headings-font-weight: 700;

$card-spacer-x: 2.5rem;